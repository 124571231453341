<template>
  <div style="background: #FFFFFF" class="index">
    <router-view class="content"/>
<!--    <van-tabbar v-model="active" active-color="#FB884F" inactive-color="#666666" class="tab">-->
<!--      <van-tabbar-item to="/lease" icon="wap-home-o" >首页</van-tabbar-item>-->
<!--      <van-tabbar-item to="/classify" icon="apps-o" >分类</van-tabbar-item>-->
<!--      <van-tabbar-item icon="service-o" @click="kf" >客服</van-tabbar-item>-->
<!--      <van-tabbar-item to="/order" icon="label-o">订单</van-tabbar-item>-->
<!--      <van-tabbar-item to="/personage" icon="contact" >我的</van-tabbar-item>-->
<!--    </van-tabbar>-->
    <van-tabbar v-model="active" active-color="#FB884F" inactive-color="#666666" class="tab">
      <van-tabbar-item to="/lease">
        <span>首页</span>
        <template #icon="props">
          <van-image :src="props.active ? require('./images/home_active1.png') : require('./images/home_index1.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/classify">
        <span>分类</span>
        <template #icon="props">
          <van-image :src="props.active ? require('./images/home_active2.png') : require('./images/home_index2.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item @click="kf">
        <span>客服</span>
        <template #icon="props">
          <van-image :src="props.active ? require('./images/home_active3.png') : require('./images/home_index3.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/order">
        <span>订单</span>
        <template #icon="props">
          <van-image :src="props.active ? require('./images/home_active4.png') : require('./images/home_index4.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/personage">
        <span>我的</span>
        <template #icon="props">
          <van-image :src="props.active ? require('./images/home_active5.png') : require('./images/home_index5.png')" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <div class="return" @click="goBack">
<!--      <div class="iconfont icon-shouye1 ico"></div>-->
      <img src="./images/gobackImg.png" alt="" style="width: 1.29rem;height: 1.29rem">
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
  name: "index",
  data(){
    return{
      active:0
    }
  },
  methods:{
    kf(){
      Dialog.confirm({
        message:"<p style=\"font-size: 0.58rem;font-weight: bold\">19150324669</p>",
        allowHtml:true,
        cancelButtonColor:'#3366FF',
        confirmButtonColor:'#3366FF'
      })
          .then(() => {
            window.location.href = 'tel://' + '19150324669'
            // on confirm
          })
          .catch(() => {
            // on cancel
          })
    },
    goBack(){
      this.$router.push({name:'home'})
    }
  },
  mounted() {
    const u = navigator.userAgent
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    if (isiOS) {
      window.history.pushState({}, 'title', '#')
    }
  }

}
</script>

<style lang="less" scoped>
///deep/.van-dialog__header--isolated{
//  font-size: 0.85rem!important;
//}

.index{
  display: flex;
  flex-direction: column;
  height: 100%;
  .tab{
    padding-top: 0.097rem;
    padding-bottom: 0.181rem;
    .van-tabbar-item{
      /deep/.van-tabbar-item__icon img{
        width: 0.694rem;
        height: 0.694rem;
      }
    }
  }
  .content{
    flex: 1;
  }
}
/deep/.van-tabbar-item__icon{
  margin-bottom: 0.02rem;
}
.return{
  position: fixed;
  left: 0.1rem;
  top: 75%;
  width: 1.083rem;
  height: 1.083rem;
  z-index: 9999;
  img{
    width: 100%;
  }
}

</style>
